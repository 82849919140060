export default function Navbar() {
  return (
    <div className="navbar">
      <div className="nav-brand">AVIOR</div>
      <div className="nav-list">
        <ul>
          <li>
            <a href="#home-section">Home</a>
          </li>
          <li>
            <a href="#projects-section">Projects</a>
          </li>
          <li>
            <a href="#followers-section">Followers</a>
          </li>
          <li>
            <a href="#stats-section">Statistics</a>
          </li>
        </ul>
        <a
          className="GitHub-icon"
          href="https://github.com/devanshuyadav/avior"
        >
          <i className="fa-brands fa-2x fa-github" />
        </a>
      </div>
    </div>
  );
}
