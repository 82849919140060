import React from "react";
import ProfileInfoCard from "./ProfileInfoCard";

export default function Profile(props) {
  return (
    <>
      <div id="home" className="profile-main">
        <div>
          Hi 👋🏻,
          <br /> I'm {props.user.name}
          <p className="profile-bio">{props.user.bio}</p>
          <div className="profile-links">
            <a
              style={
                props.user.twitter_username === null
                  ? { pointerEvents: "none", opacity: "0.2" }
                  : { color: "#00acee" }
              }
              href={
                props.user.twitter_username === null
                  ? "#"
                  : `https://twitter.com/${props.user.twitter_username}`
              }
            >
              <i className="fa-brands fa-twitter"></i>
            </a>
            <a
              style={
                props.user.twitter_username === null
                  ? { pointerEvents: "none", opacity: "0.2" }
                  : { color: "#EA4335" }
              }
              href={
                props.user.location === null
                  ? "#"
                  : `https://www.google.com/maps/place/${props.user.location}`
              }
            >
              <i className="fa-solid fa-location-dot"></i>
            </a>
            <a href={`${props.user.html_url}`}>
              <i className="fa-brands fa-github"></i>
            </a>
          </div>
          <ProfileInfoCard
            col1={props.user.public_repos}
            col2="Public repositories"
          />
          <ProfileInfoCard col1={props.user.public_gists} col2="Public gists" />
        </div>
        <img
          className="profile-pic"
          alt="profile pic"
          src={props.user.avatar_url}
        />
      </div>
    </>
  );
}
