import React from "react";

var months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default function RepoCard(props) {
  return (
    <>
      <div className="repo-parent">
        <div className="repo-card">
          <div className="repo-header">
            <a href={props.repo.html_url}>
              <span className="repo-name">{props.repo.name}</span>
              <i className="fa-solid fa-arrow-up-right-from-square"></i>
            </a>
            <span class="repo-date">
              <i className="fa-solid fa-calendar-days"></i>
              {" " + props.repo.created_at.slice(8, 10) + " "}
              {" " + months[parseInt(props.repo.created_at.slice(5, 7))] + " "}
              {props.repo.created_at.slice(0, 4)}
            </span>
          </div>
          <div className="repo-content">{props.repo.description}</div>
        </div>
      </div>
    </>
  );
}
