import React from "react";
import FollowersCard from "./FollowersCard";

export default function Followers(props) {
  return (
    <div id="followers-section">
      <h1 className="section-heading">Followers</h1>
      <div className="fc-parent">
        {props.followersArr.slice(0, 15).map((follower) => (
          <FollowersCard follower={follower} />
        ))}
      </div>
    </div>
  );
}
