import React from "react";
const Form = (props) => {
  return (
    <div className="form-div">
      <form
        className="input-form"
        onSubmit={(event) => props.handleUserFormSubmit(event)}
      >
        <label>
          <input
            className="input-field"
            name="username"
            type="text"
            autoComplete="off"
            placeholder=" "
            required
            value={props.formData.username}
            onChange={props.handleFormChange}
          />
          <span className="input-span">GitHub username</span>
        </label>
        <div>
          <input className="submit-btn" type="submit" value="Enter" />
        </div>
      </form>
    </div>
  );
};
export default Form;
