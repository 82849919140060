import React, { Component } from "react";
import Followers from "./Followers.jsx";
import Footer from "./Footer.jsx";
import Form from "./Form.jsx";
import Profile from "./Profile.jsx";
import Projects from "./Projects.jsx";
import Statistics from "./Statictics.jsx";

class Fetch extends Component {
  constructor() {
    super();
    this.state = {
      repos: [],
      followersArr: [],
      username: "No username",
      user: "",
      formData: {
        username: "",
      },
    };
    this.handleUserFormSubmit = this.handleUserFormSubmit.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  handleUserFormSubmit(e) {
    e.preventDefault();
    fetch(`https://api.github.com/users/${this.state.formData.username}`)
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          username: json.login,
          user: json,
          isLoaded: true,
        });
      });
    fetch(`https://api.github.com/users/${this.state.formData.username}/repos`)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ repos: json });
      });
    fetch(
      `https://api.github.com/users/${this.state.formData.username}/followers`
    )
      .then((res) => res.json())
      .then((json) => {
        this.setState({ followersArr: json });
      });
  }

  handleFormChange(event) {
    const obj = this.state.formData;
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  }

  render() {
    const { user, isLoaded, repos, followersArr } = this.state;
    if (isLoaded) {
      return (
        <>
          <Profile user={user} />
          <Statistics user={user} />
          <Projects reposArr={repos} />
          <span className="more-projects">
            <a
              href={`https://github.com/${this.state.formData.username}?tab=repositories`}
            >
              More Projects
              <i className="fa-solid fa-arrow-right"></i>
            </a>
          </span>
          <Followers followersArr={followersArr} />
          <Footer />
        </>
      );
    } else
      return (
        <div>
          <Form
            formData={this.state.formData}
            handleUserFormSubmit={this.handleUserFormSubmit}
            handleFormChange={this.handleFormChange}
          />
        </div>
      );
  }
}
export default Fetch;
