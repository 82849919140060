import React from "react";

export default function Statictics(props) {
  return (
    <>
      <h1 id="stats-section" className="section-heading">
        Statistics
      </h1>
      <div className="stats-section">
        <img
          className="mul"
          src={`https://github-readme-stats.vercel.app/api?username=${props.user.login}&show_icons=true&theme=graywhite&locale=en`}
          alt="GitHub Stats"
        />
        <img
          className="mul"
          src={`https://github-readme-stats.vercel.app/api/top-langs?username=${props.user.login}&show_icons=true&locale=en&theme=graywhite&layout=compact`}
          alt="Most Used Languages"
        />
        <img
          src={`https://github-readme-streak-stats.herokuapp.com/?user=${props.user.login}&theme=graywhite`}
          alt="Streaks"
        />
      </div>
    </>
  );
}
