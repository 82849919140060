import React from "react";
import RepoCard from "./RepoCard";

export default function Projects(props) {
  return (
    <div id="projects-section" className="projects-section">
      <h1 className="section-heading">Projects</h1>
      {props.reposArr.slice(0, 5).map((repo) => (
        <RepoCard repo={repo} />
      ))}
    </div>
  );
}
