import React from "react";

export default function ProfileInfoCard(props) {
  return (
    <div>
      <div className="profile-info">
        <strong className="profile-info-number">{props.col1}</strong>
        <span>{props.col2}</span>
      </div>
    </div>
  );
}
