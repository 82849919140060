import React from "react";

export default function FollowersCard(props) {
  return (
    <div>
      <span className="fc-card">
        <a href={props.follower.html_url}>
          <img className="fc-image" src={props.follower.avatar_url} alt="followerimg" />
          <span>@{props.follower.login}</span>
        </a>
      </span>
    </div>
  );
}
